@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $value;
	}
	flex-wrap: $value;
}

@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int;
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}

@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}

@mixin align-content($value: stretch) {
	// No Webkit Box Fallback.
	-webkit-align-content: $value;
	-moz-align-content: $value;
	@if $value == flex-start {
		-ms-flex-line-pack: start;
	} @else if $value == flex-end {
		-ms-flex-line-pack: end;
	} @else {
		-ms-flex-line-pack: $value;
	}
	align-content: $value;
}

@mixin border-radius( $args... ) {
	-webkit-border-radius: $args;
	-moz-border-radius: $args;
	border-radius: $args;
}

@mixin box-shadow( $args... ) {
	-webkit-box-shadow: $args;
	-moz-box-shadow: $args;
	box-shadow: $args;
}

@mixin transform( $args... ) {
	-webkit-transform: $args;
	-moz-transform: $args;
	transform: $args;
}

@mixin transition( $args... ) {
	-webkit-transition: $args;
	-moz-transition: $args;
	transition: $args;
}

@mixin transition-duration( $args... ) {
	-webkit-transition-duration: $args;
	-moz-transition-duration: $args;
	transition-duration: $args;
}

@mixin transition-delay( $args... ) {
	-webkit-transition-delay: $args;
	-moz-transition-delay: $args;
	transition-delay: $args;
}

@mixin filter( $args... ) {
	-webkit-filter: $args;
	-moz-filter: $args;
	-o-filter: $args;
	-ms-filter: $args;
	filter: $args;
}

@mixin x-center() {
	position:absolute;
	left:50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

@mixin y-center() {
	top:50%;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin xy-center() {
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@mixin breakpoint-min($point) {
	@if $point == xs {
		@media (min-width: 480px) { @content ; }
	}
	@else if $point == sm {
		@media (min-width: 768px) { @content ; }
	}
	@else if $point == md {
		@media (min-width: 992px) { @content ; }
	}
	@else if $point == lg {
		@media (min-width: 1280px)  { @content ; }
	}
	@else if $point == xl {
		@media (min-width: 1920px)  { @content ; }
	}
}

@mixin breakpoint-max($point) {
	@if $point == xs {
		@media (max-width: 480px) { @content ; }
	}
	@else if $point == sm {
		@media (max-width: 768px) { @content ; }
	}
	@else if $point == md {
		@media (max-width: 1024px) { @content ; }
	}
	@else if $point == lg {
		@media (max-width: 1440px)  { @content ; }
	}
	@else if $point == xl {
		@media (max-width: 1920px)  { @content ; }
	}
}


@mixin box-sizing($box) {
	-webkit-box-sizing: $box;
	-moz-box-sizing: $box;
	box-sizing: $box;
}

@mixin gradient($direction, $from, $to) {
	background: -webkit-linear-gradient($direction, $from, $to);
	background: -moz-linear-gradient($direction, $from, $to);
	background: -o-linear-gradient($direction, $from, $to);
	background: linear-gradient($direction, $from, $to);
}
