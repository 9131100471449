@import "./common/mixins";
@import "./common/variables";
@import "./common/global";

.main {
    width: 100%;
    height: 100%;

    .container {
        width: 100%;
        height: 100%;
    }

    .section-wrapper {
        @include flexbox();
        @include align-items(flex-end);
        @include justify-content(center);
        width: 100%;
        height: 100%;
        min-height: 700px;
        background-size: cover;
        background-image: url("../img/index_img.jpg");
    }

    .text-wrapper {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        text-align: center;
        margin: 0 0 80px;
        padding: 40px;
        width: 50%;
        background-color: rgba(255,255,255,0.7);

        h3 {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            margin: 0 0 10px;
        }

        p {
            font-size: 12px;
            line-height: 24px;
        }

        button {
            margin: 20px 0 0;
        }
    }
}

.site-matches {
    width: 100%;
    padding: 30px 0 40px;

    h2 {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .section-wrapper {
        @include flexbox();
        @include justify-content(space-between);
        gap: 20px;
        margin: 30px 0 0;
    }

    .match {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        gap: 15px;
        width: 100%;

        img {
            width: 100%;
        }

        p {
            font-size: 10px;
            line-height: 16px;
            font-weight: 400;
            text-align: center;
            padding: 0 10px;
            min-height: 65px;
        }
    }
}

.why-choose {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    min-height: 630px;
    background: linear-gradient(to right top, $primary 0%, $primary-light 100%);

    .text-wrapper {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        gap: 20px;
        width: 60%;
        text-align: center;
        padding: 40px 30px 20px;
        background-color: white;
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
        line-height: 24px;
    }
}

.success-stories {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    min-height: 630px;
    background-size: cover;
    background-image: url("../img/BG-IMG-2.jpg");

    .text-wrapper {
        @include flexbox();
        @include align-items(center);
        @include flex-direction(column);
        gap: 20px;
        width: 55%;
        text-align: center;
        padding: 30px;
        background-color: rgba(255,255,255,0.7);
    }

    h3 {
        font-size: 24px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
        line-height: 20px;
    }
}

@include breakpoint-max(md) {
    .main {
        .container {
            max-width: 100%;
        }

        .section-wrapper {
            min-height: calc(100vh - 78px);
            background-image: url("../img/index_img_mobile.jpg");
        }

        .text-wrapper {
            width: 90%;
            padding: 25px 15px;
        }
    }

    .site-matches {
        .section-wrapper {
            @include flex-direction(column);
        }

        .match {
            margin: 0 0 20px;
        }
    }

    .why-choose, .success-stories {
        padding: 20px 0;

        .text-wrapper {
            width: 90%;
            padding: 30px 20px;
        }
    }
}
